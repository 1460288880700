import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Style from "./PaymentAddress.module.scss";
import countries from "../../../utils/Countries/index";
import Select from "react-select";

const PaymentAddressDropdown = ({ data, setFieldValue,value}) => {
  const [activeVal, setActiveVal] = useState("Select a country");

  const handleChangeSelect = (e) => {
    setFieldValue("country", e.value);
  };

  let newContryDate = countries?.map((item, index) => {
    let data = {
      ...item,
      label: item?.label?.toUpperCase(),
      value: item?.value?.toUpperCase(),
    };
    return data;
  });

  return (
    <>
      <Select
        className={`basic-single ${Style.Dropdown_select}`}
        classNamePrefix="select"
        // defaultValue={countries[0]}
        value={newContryDate?.find((e)=>value==e?.value)}
        isSearchable={true}
        options={newContryDate}
        onChange={handleChangeSelect}
        // onFocus={() => setIsSelectFocus(true)}
        // onBlur={() => setIsSelectFocus(false)}
      />
    </>
  );
};

export default PaymentAddressDropdown;
