import React from "react";
import { useSelector } from "react-redux";
import Style from "./FamilyUsersPrice.module.scss";
import moment from "moment";
import Assets from "../Layouts/Assets";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "react-router-dom";
const FamilyUsersPrice = ({ collapseMenu }) => {
  const { userdata } = useSelector((state) => state.userprofile);
  return (
    <>
      {!collapseMenu ? (
        <div
          className={`${Style.family_users} amily-users-ad`}
          style={{
            "--bgimg": "url(" + Assets.dashboardLeftBarUsageBg + ")",
          }}
        >
          <div className={`${Style.family_users_TopContainer}`}>
            <p className={Style.family_users_usage_title}>Usage</p>
            {userdata?.trail_account_status === 1 ? <span>Trial</span> : ""}
          </div>
          <span className={Style.family_users_usage}>
            {userdata?.percentage > 100 ? 100 : userdata?.percentage}%
          </span>
          {/* <p className={Style.family_users_expiry}>
        Purchased on{" "}
        {moment(userdata?.activated_plan?.[0]?.payment_details?.purchased_date).format("DD MMM YYYY")}
      </p> */}
          <ProgressBar
            variant={userdata?.percentage >= 90 ? "danger" : "warning"}
            now={userdata?.percentage}
            style={{ height: "4px", margin: "10px 0" }}
          />
          {/* <hr /> */}
          <p className={Style.family_users_expiry}>
            Expires on{" "}
            {moment(userdata?.activated_plan?.[0]?.expires_at).format(
              "DD MMM YYYY"
            )}
          </p>
          <div className={Style.family_users_title}>
            <span>{userdata?.mail}</span>
          </div>
          {userdata?.is_package && <Link className="btn btn-sm btn-outline-black" to="/choose-plan">Renew</Link>}
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <span
            className={Style.family_users_count}
            style={{ fontSize: "14px", width: "100%" }}
          >
            {userdata?.percentage ? userdata?.percentage : 0}%
          </span>
          <ProgressBar
            variant={userdata?.percentage >= 90 ? "danger" : "warning"}
            now={userdata?.percentage}
            style={{ height: "4px", margin: "10px 0" }}
          />
        </div>
      )}
    </>
  );
};

export default FamilyUsersPrice;
